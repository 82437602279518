
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  data () {
    return {}
  },
  computed: {
    Tabs () {
      return [
        { Name: this.Translate('System'), Link: '/Settings/System', Value: 'System', Access: this.Access('Main_Settings_System') },
        { Name: this.Translate('Company'), Link: '/Settings/CompanyInfo', Value: 'Company', Access: this.Access('Main_Settings_Company') },
        { Name: this.Translate('Account'), Link: '/Settings/Account', Value: 'Account', Access: this.Access('Main_Settings_Account') },
        { Name: this.Translate('Integration'), Link: '/Settings/Integration', Value: 'Integration', Access: this.Access('Main_Settings_Integration') },
        { Name: this.Translate('Super Admin'), Link: '/Settings/SuperAdmin', Access: this.Access('SuperAdmin') }
      ] as Array<CookiesTabs>
    }
  }
})
